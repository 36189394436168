import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react';
import CTA from '../components/CTA-newsletter';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Link from '../components/link';
import Layout from '../layout';

const styles = css`
  .grid {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 4rem;
    justify-content: center;

    & > div {
      flex: 1 1 calc(50% - 1rem);
      position: relative;
      padding: 4rem 0rem;
      text-align: left;

      display: flex;
      align-items: flex-start;

      gap: 1rem;
      min-width: min(300px, 100%);
      max-width: 450px;

      & svg {
        min-width: 60px;
        width: 20%;
        margin: 0;
      }

      & h3 {
        margin: 0;
        font-size: 1.2rem;
      }
    }
  }

  .button {
    position: relative;
    outline: none;
    align-self: center;
    z-index: 2;
    border: 0px;
    height: 35px;
    line-height: 35px;
    border-radius: 40px;
    font-size: 1rem;
    color: rgb(255, 255, 255);
    font-weight: 700;
    white-space: nowrap;
    opacity: 1;
    padding: 0px;
    background: linear-gradient(135deg, rgb(44, 130, 173), rgb(103, 178, 111));
    margin-top: 1rem;
    display: inline-block;

    & i {
      display: inline-block;
      height: 35px;
      line-height: 35px;
      border-radius: 40px;
      padding: 0px 1rem;
      transition: background 0.25s ease-out 0s;
      background: rgb(10, 21, 48);
      font-style: normal;
    }

    &:is(:hover, :focus) i {
      background: transparent;
    }
  }
`;

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Promotions and discounts to pay less"
          keywords={['discount', 'discount code', 'promotion']}
          pathname={this.props.location.pathname}
        />
        <PageHeader>
          <StaticImage
            src="../../assets/images/icon.png"
            alt=""
            objectFit="contain"
            placeholder="none"
            width={150}
            formats={['PNG']}
          />
          <h1>Special offers</h1>
          <h2>Promotions and discounts to pay less or get Polypane for free.</h2>
        </PageHeader>

        <div className="page" style={{ maxWidth: '60rem' }}>
          <div className={styles.grid}>
            <div>
              <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <title>GitHub icon</title>
                <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
              </svg>
              <div>
                <h3>Free through the GitHub Student Developer Pack</h3>
                <p>Students enrolled in the GitHub Student Developer pack get a full year of Polypane for free. </p>
                <Link className={styles.button} to="/github-students/">
                  <i>Sign up</i>
                </Link>
              </div>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#000000"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6" />
                <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4" />
              </svg>
              <div>
                <h3>50% discount for educators</h3>
                <p>
                  Educators can get a yearly individual license with a 50% discount by{' '}
                  <Link to="/support/">sending proof</Link> of association.
                </p>

                <Link className={styles.button} to="/support/">
                  <i>Contact us</i>
                </Link>
              </div>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#000000"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4" />
                <circle cx="18" cy="18" r="4" />
                <path d="M15 3v4" />
                <path d="M7 3v4" />
                <path d="M3 11h16" />
                <path d="M18 16.496v1.504l1 1" />
              </svg>
              <div>
                <h3>2 months free with a Yearly plan</h3>
                <p>With the yearly plan you get two months for free each year! </p>
                <Link className={styles.button} to="/pricing/">
                  <i>Get a Yearly plan</i>
                </Link>
              </div>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#000000"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="9" cy="7" r="4" />
                <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
              </svg>
              <div>
                <h3>Save up to 50% per user with a business plan</h3>
                <p>With a 10 seat Business plan you get up to a 50% discount per seat. </p>
                <Link className={styles.button} to="/pricing/">
                  <i>Get a Business plan</i>
                </Link>
              </div>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-heart"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#000000"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
              </svg>
              <div>
                <h3>Registered charities get 50% off</h3>
                <p>
                  Registered charities can get a yearly business or individual license with a 50% discount (conditions
                  apply). Request a discount code by sending info about the charitable status of the organisation.
                </p>

                <Link className={styles.button} to="/support/">
                  <i>Contact us</i>
                </Link>
              </div>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#000000"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="3" y1="21" x2="21" y2="21" />
                <line x1="9" y1="8" x2="10" y2="8" />
                <line x1="9" y1="12" x2="10" y2="12" />
                <line x1="9" y1="16" x2="10" y2="16" />
                <line x1="14" y1="8" x2="15" y2="8" />
                <line x1="14" y1="12" x2="15" y2="12" />
                <line x1="14" y1="16" x2="15" y2="16" />
                <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
              </svg>
              <div>
                <h3>Volume licensing for educational institutions</h3>
                <p>
                  We offer volume licensing options for educational institutions.
                </p>
                <Link className={styles.button} to="/support/">
                  <i>Contact us</i>
                </Link>
              </div>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#000000"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M9 14c0 1.657 2.686 3 6 3s6 -1.343 6 -3s-2.686 -3 -6 -3s-6 1.343 -6 3z" />
                <path d="M9 14v4c0 1.656 2.686 3 6 3s6 -1.344 6 -3v-4" />
                <path d="M3 6c0 1.072 1.144 2.062 3 2.598s4.144 .536 6 0c1.856 -.536 3 -1.526 3 -2.598c0 -1.072 -1.144 -2.062 -3 -2.598s-4.144 -.536 -6 0c-1.856 .536 -3 1.526 -3 2.598z" />
                <path d="M3 6v10c0 .888 .772 1.45 2 2" />
                <path d="M3 11c0 .888 .772 1.45 2 2" />
              </svg>
              <div>
                <h3>Purchase power parity</h3>
                <p>
                  For countries with currencies that are under-valued compared to the Euro. Request a PPP discount code
                  by sending proof of your location.
                </p>
                <Link className={styles.button} to="/support/">
                  <i>Contact us</i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <CTA />
      </Layout>
    );
  }
}

export default Page;
